<template>

<app-wrapper class="form">

	<app-wrapper :content="true" class="form-content">

		<h2>{{ $state.page.title }}</h2>

		<p class="form-message is-success" v-if="is.success">{{ $state.page.success_complete }}</p>

		<p class="form-message is-error" v-if="is.errored">{{ $state.page.error_invalid }}</p>

		<template v-if="!is.success">
		
			<app-textbox v-model="model.name" placeholder="Name" />
			<app-textbox v-model="model.company" placeholder="Company" />
			<app-textbox v-model="model.location" placeholder="Location" />
			<app-textbox v-model="model.email" placeholder="E-mail Address" />
			<app-textbox v-model="model.phone" placeholder="Phone Number" />
			<app-textbox v-model="model.message" placeholder="Message" :area="true" />

			<vue-recaptcha ref="captcha" v-on:verify="onVerified" sitekey="6LcQoFskAAAAAKJDO3QeBoTCTtDV2DnWzvLBfvoG" class="form-captcha" />

			<p class="form-policy">{{ $state.page.form_policy }} <router-link :to="{name: 'Generic', params: { page: 'privacy-policy' }}" target="_blank">privacy policy</router-link>.</p>

			<button class="form-submit" :class="{'is-disabled': is.submitting || $v.$invalid, 'is-errored': is.errored}" v-on:click="onSubmitClick">
				<template v-if="!is.submitting && !is.errored">{{ $state.page.form_button }}</template>
				<template v-if="!is.submitting && is.errored">Please try again</template>
				<i class="fa fa-spinner fa-spin" v-if="is.submitting"></i>
			</button>

		</template>

	</app-wrapper>

</app-wrapper>

</template>

<script>

import { VueRecaptcha } from 'vue-recaptcha'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'

export default {

	mixins: [validationMixin],

	components: {
		VueRecaptcha
	},

	data: function() {

		return {
			is: {
				submitting: false,
				errored: false,
				success: false
			},
			model: {
				email: '',
				name: '',
				location: '',
				phone: '',
				company: '',
				message: '',
				captcha: ''
			}
		}

	},

	validations: {
		model: {
			name: {
				required
			},
			email: {
				required,
				email
			},
			location: {
				required
			},
			phone: {
				required
			},
			company: {
				required
			},
			message: {
				required
			},
			captcha: {
				required
			}
		}
	},

	watch: {

		model: {

			handler: function() {

				this.is.errored = false
				this.$v.$touch()

			},

			deep: true

		} 

	},

	methods: {

		onVerified: function(e) {

			this.model.captcha = e

		},

		onSubmitClick: function() {

			if (this.is.submitting || this.$v.$invalid) return false

			this.is.submitting = true
			this.is.errored = false

			this.$api.post('contact', this.model).then(function() {

				this.is.submitting = false
				this.is.success = true

			}.bind(this), function() {

				this.$refs.captcha.reset()

				this.is.submitting = false
				this.is.errored = true

			}.bind(this))

		}
			
	}

}

</script>

<style scoped>

.form-content {
	background-color: #fff;
	border-radius: 15px 15px 0px 0px;
	margin-top: -300px!important;
	min-height: 300px;
	padding: 40px;
}

.is-mobile .form-content {
	margin-top: 0px!important;
	border-radius: 0px;
	padding: 30px 10px!important;
}

.form-content p.form-policy {
	text-align: center;
	font-size: 16px;
	line-height: 20px;
	color: #585A5D;
	padding: 40px 0px;
}

.form-content p.form-message {
	text-align: center;
	font-size: 16px;
	line-height: 20px;
	color: #fff;
	padding: 20px;
	border-radius: 5px;
}

.form-content p.form-message.is-success {
	background-color: #BBD9CD;
}

.form-content p.form-message.is-error {
	background-color: #cc6e73;
}

.is-mobile .form-content p.form-policy {
	font-size: 14px;
	line-height: 18px;
	padding: 30px 0px;
}

.form-content p.form-policy a {
	font-weight: 500;
}

.form-captcha {
	margin: 0px auto;
	width: 300px;
	margin-top: 20px;
}

.form-submit {
	height: 56px;
	border-radius: 36px;
	font-size: 16px;
	font-weight: 500;
	color: #112A45;
	width: 200px;
	text-align: center;
	user-select: none;
	line-height: 56px;
	background-color: #BBD9CD;
	cursor: pointer;
	margin: 0px auto;
    display: block;
}

.form-submit.is-disabled {
	pointer-events: none;
	opacity: 0.75;
}

.form-submit.is-errored {
	color: #fff;
	background-color: #cc6e73;
}

.is-tablet .form-submit,
.is-mobile .form-submit {
	height: 48px;
	line-height: 48px;
	font-size: 14px;
}

.is-desktop .form-submit:hover {
	background-color: #112a45;
	color: #fff;
}

</style>
